@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Karla;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Karla-Regular.ttf);
}

body {
  margin: 0;
  font-family: Karla;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

@keyframes vertical-pan {
  from {
    background-position: center 0%;
  }
  to {
    background-position: center -100%;
  }
}

.scifi {
  background: linear-gradient(to bottom, purple, violet, pink, purple);
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: vertical-pan 2s linear infinite;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
